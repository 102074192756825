const Boxes = {
  box1: function () {
JXG.Options.board.minimizeReflow = 'none';
var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 21, 21, -2], keepaspectratio: true, axis:false, ticks:true,
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.suspendUpdate();
//
var resize1 = function () {
  brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
  brd1.fullUpdate();};
window.addEventListener("resize", resize1);
var i=1;
brd1.options.layer['image'] =14;
//resizing
  var resize = function () {
      brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
      brd1.fullUpdate();
      };
window.onresize = resize;
//Title and subtitle
brd1.create('text', [9, 19, 'Constant Polynomial'],{visible:()=>i<=1, highlight:false, fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald;font-weight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}});
brd1.create('text', [9, 19, 'Linear Polynomial'],{visible:()=>i==2, highlight:false,fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald;font-weight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}},);
brd1.create('text', [9, 19, 'Quadratic Polynomial'],{visible:()=>i>=3, highlight:false, fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald;font-weight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}},);
brd1.create('text', [9, 15, 'Drag point B to change x and evaluate N(x) at that x.'],{highlight:false,visible:()=>i==1,fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);
brd1.create('text', [9, 15, 'Drag point B to change x and evaluate P(x) at that x.'],{highlight:false,visible:()=>i==2,fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);
brd1.create('text', [9, 15, 'Drag point B to change x and evaluate A(x) at that x.'],{highlight:false,visible:()=>i==3,fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);
brd1.create('text', [9, 17, 'Number of Sides in a Square: N(x) = 4'],{highlight:false,visible:()=>i<=1, fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald;font-weight:bold',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
//
brd1.create('text', [9, 17, 'Perimeter of a Square: P(x) = 4 x'],{highlight:false,visible:()=>i==2, fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald;font-weight:bold', parse:true, fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
//
brd1.create('text', [7, 17, 'Area of a Square: A(x) = x^2'],{highlight:false,visible:()=>i>=3, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald;font-weight:bold',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}})
//var txt3 = brd1.create('text', [7, 7, 'What is the order of the polynomial ?  '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
//brd1.create('text', [7, 7, 'What is the order of the polynomial ?  '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
//points
var diag = brd1.create('segment', [[1,0], [12, 0]], {visible:false});
var extreme1 = brd1.create('point', [0,0],{fixed:true, visible:true, name:'A', strokeColor:'black', fillColor:'white', size:3, label: {offset:[0, -15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var extreme2 = brd1.create('glider', [4,0, diag],{snapToGrid:false, name:()=>'B (Drag me!)', snapToGrid:true, strokeColor:'black', fillColor:'red', size:3, label: {offset:[0, -15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var extreme3 = brd1.create('point', [()=>extreme2.X(), ()=>extreme2.X()],{name:'C', size:3, strokeColor:'black', fillColor:'white', label: {offset:[0, 15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var extreme4 = brd1.create('point', [0,()=>extreme2.X()],{fixed:true, name:'D', strokeColor:'black', fillColor:'white', size:3, label: {offset:[0, 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
brd1.create('text', [9, 13, ()=>'N(@x= ' + (extreme2.X()).toFixed(0) +') = 4'],{highlight:false,visible:()=>i<=1,fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
//
//brd1.create('point', [()=>extreme2.X(), ()=>extreme2.X()*extreme2.X()],{name:'', size:1, trace:true, face:'square', strokeColor:'black', fillColor:'yellow', snapToGrid:true, label: {offset:[0, 15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
brd1.create('text', [9, 13, ()=>'P(@x='+(extreme2.X()).toFixed(0)+') = 4x'+(extreme2.X()).toFixed(0)+'='+(4*extreme2.X()).toFixed(0)], {highlight:false,visible:()=>i==2, fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
//
brd1.create('text', [9, 13, ()=>'A(@x='+(extreme2.X()).toFixed(0)+') =' + (extreme2.X()).toFixed(0)+'x'+(extreme2.X()).toFixed(0) +' ='+ (extreme2.X()*extreme2.X()).toFixed(0)],{highlight:false, visible:()=>i>=3,fixed: true, anchorX: 'middle', anchorY: 'middle', display:'internal', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
//Polygons
brd1.create('polygon', [extreme1,extreme2, extreme3, extreme4],{highlight:false, fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'black'},visible: true});

brd1.create('text', [()=>extreme3.X()/2, -1., ()=>'x='+(extreme3.X()).toFixed(0)],{highlight:false,fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:()=>Math.round(22*brd1.canvasWidth/800.)});
brd1.create('text', [-1., ()=>extreme3.X()/2, ()=>'x='+(extreme3.X()).toFixed(0)],{highlight:false,fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:()=>Math.round(22*brd1.canvasWidth/800.)});



//Images
var undo = brd1.create('image', ['/assets/redo.svg', [14, 18], [2 , 2]], {visible: true, fixed: true});
undo.setLabel('Next')
undo.label.setAttribute({visible:false, anchorX:'left', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
undo.on('over', function () {this.label.setAttribute({visible:true});});
undo.on('out', function () {this.label.setAttribute({visible:false});});
undo.on('down', function(){extreme2.moveTo([4,0]);if(i<3){return i+=1}else{return i=3}})
//
var redo = brd1.create('image', ['/assets/undo.svg', [2, 18], [2 , 2]], {visible: true, fixed: true});
redo.setLabel('Previous')
redo.label.setAttribute({visible:false, anchorX:'left', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
redo.on('over', function () {this.label.setAttribute({visible:true});});
redo.on('out', function () {this.label.setAttribute({visible:false});});
redo.on('down', function(){extreme2.moveTo([4,0]);if(i>1){return i-=1}else{return i=1}})
brd1.unsuspendUpdate();
}
}
export default Boxes;
