<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Introduction</span>
    </div>
    <div style="margin-top: 10px" />
    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-flex xs12
              sm12
              md12
              lg12
              xl12
              class="font-weight-light"
              :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        <span class="subhead">A</span> polynomial is an algebraic expression that is obtained by addition, subtraction and multiplication of
        integer power of variables and constant coefficients.  By convention, a polynomial in a given variable is denoted by lower case alphabets, e.g., polynomials in variable \(x\)
        can be denoted as \(p(x)\), \(q(x)\), \(r(x)\) etc.
        For example \(p(x) =x^2 + 4 x +4\) is a polynomial in variable \(x\). Note that a variable
        is an entity that doesn't have a fixed value and can assume multiple values as opposed to a constant which can have only one value.
        <br> <br>
        Polynomials are used in many areas of mathematics and science. Let's explore polynomials using a simple example.
        Imagine you are given a square. Each side of the square is \(x\) units in length.
        By changing \(x\), the size of the square can be changed.
        <br><br>
        <ul class="a">
          <li> <h5> Constant Polynomial </h5></li>
          Example would be the number of sides in a square: \(N(x)=4\) (a constant).
          It is a constant because its value does not depend on the length of the side i.e.
          the number of sides in a square is always 4 irrespective of the value of \(x\).
          <br><br>
          <li> <h5> First-Order (Linear) Polynomial </h5></li>
          Example would be the perimeter of a square as a function of its side  \(x\): \(P(x) = 4x\) units. This is a first-order (also known as linear) polynomial in \(x\).
          The perimeter increases linearly with side length \(x\).
          <br><br>
          <li> <h5> Second-Order (Quadratic) Polynomial </h5></li>
          Example would be the area of a square as a function of its side \(x\):  \( A(x) = x^2\) square units. This is a second-order (also known as quadratic) polynomial in \(x\).
          The area of the square increases quadratically with its side \(x\).
        </ul>
        <h5 style="text-align:center">
          Explore polynomials using the simple example of a square.
        </h5>
        <v-responsive>
          <v-layout justify-center>
            <div id="jxgbox1" class="edliy-box-about" />
          </v-layout>
        </v-responsive>
        <br> <br>
        Note that all the polynomials discussed so far contain only one term in their expression.
        Such polynomial expressions are known as monomials (recall 'mono' means 'one').
        Let's construct a polynomial expression that contains more than one term. Imagine you are asked to come up with the formula that gives the sum of number of sides, the perimeter and the area of a square of any side length.
        Such an expression can be obtained by simply adding the three monomial expressions \(P(x)\),\(Q(x)\) and \(R(x)\) .
        $$S(x) = N(x)+P(x)+A(x) = 4 +4 x +x^2$$
        <br>
        This polynomial expression allows us to calculate the sum of the number of sides, the perimeter and the area of the square for any given side.
        For a square with each side 1 unit in length, we can calculate this sum by evaluating the polynomial expression at \(x=1\), i.e.
        $$S(@x=1) = 1^2+ 4 \times 1 + 4 = 9$$
        For a square with each side 3 units in length, we substitute \(x=3\) and obtain
        $$S(@x=3) = 3^2+ 4 \times 3 + 4 = 25$$
        <div style="margin-top: 20px" />
        <div class="font-weight-medium"
             :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Outline</span>
        </div>
        <div style="margin-top: 10px" />
        This chapter on polynomials consists of four lessons. Each lesson comes with a visually interactive MagicGraph that offers students a uniquely engaging and effective learning experience.
        <h5> Lesson 1 </h5>
        Students will learn about the order of a polynomial, and how to calculate it.
        <h5> Lesson 2 </h5>
        Students will learn about coefficients of a polynomial, and how to calculate them.
        <h5> Lesson 3 </h5>
        Students will learn about zeros of a polynomial, and how to calculate the zeros of a polynomial.
        <h5> Lesson 4 </h5>
        Students will learn about the remainder theorem of polynomial division.
        <br><br>
      </v-flex>
    </v-layout>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Let's get started</span>
    </div>
    <div style="margin-top: 10px" />
    <div class="font-weight-light"
         :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
    >
      <span class="subhead">E</span>xplore our comprehensive library of interactive lessons on polynomials.
    </div>
    <div style="margin-top: 20px" />
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../../../views/SubTopics.vue'
import Boxes from './Boxes.js'
export default {
    name: 'Polynomials',
    components: {
    SubtopicCard
    },
    data () {
      return {
        playerOptions: {
        preload:'auto',
        ratio: '16:9'
        },
      topics: [
      { headline: "Coefficients of a Polynomial", description: "Kirchhoff’s voltage law can be used to calculate voltage drops across different components of an electric circuit, simple or complex.",
        img: "/assets/wdice-1.svg", path: "/mathematics/polynomials/coefficients"
      },
      {
        headline: "Order of a Polynomial", description: "Ohm's law is a fundamental principle that relates the voltage applied across a conducting material to the electric current flowing through it.  ",
        img:"/assets/wdice-2.svg", path: "/mathematics/polynomials/order"
      },
      { headline: "Zeros of a Polynomial", description: "A resistor is an Ohmic device that is used to limit the flow of charge in a circuit",
          img: "/assets/wdice-3.svg", path: "/mathematics/polynomials/zeros"
      },
      {
        headline: "Remainder Theorem", description: "Most basic type of electric circuit in which a resistor and a capacitor are connected in series. ",
          img: "/assets/wdice-4.svg", path: "/comingsoon"
      }
      ]
    }
  },
    created () {
      this.$store.commit('navigation/resetState');
      this.$store.commit('navigation/changeTitle', 'Polynomials');
      this.$store.commit('navigation/changeMenu', 'Polynomials');
      let newTopics = [
        {title: 'Coefficients of a Polynomial', img:'/assets/wdice-1.svg', action: () => this.$router.push({path: '/mathematics/polynomials/coefficients'})},
        {title: 'Order of a Polynomial', img:'/assets/wdice-2.svg', action: () => this.$router.push({path: '/mathematics/polynomials/order'})},
        {title: 'Zeros of a Polynomial', img:'/assets/wdice-3.svg', action: () => this.$router.push({path: '/mathematics/polynomials/zeros'})},
        {title: 'Remainder Theorem',img:'/assets/wdice-4.svg', action: () => this.$router.push({path: '/comingsoon'})},
      ];
      this.$store.commit('navigation/replaceTopics', newTopics);
      let newshowhome = false;
      this.$store.commit('navigation/toggleshowhome', newshowhome);
      let newMath =true;
      this.$store.commit('navigation/replaceMath', newMath);
      let newLeftArrow =true;
      this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
      let newModule=true;
      this.$store.commit('navigation/replaceModule', newModule); },
      mounted () {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        Boxes.box1();
      },
      metaInfo() {
      return{ title: 'Polynomials',
              titleTemplate: '%s - Learn interactively',
              meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                      {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                    ]
            }
       }
}
</script>
<style scoped lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
